<template>
  <div class="menuBar">
    <div class="barbox">
      <div class="logo" v-if="logoif">
        <img src="../assets/imgs/logo.png" style="margin-right: 11px;" alt />
        <img src="../assets/imgs/title.png" alt />
        <div class="searchInput">
          <el-input v-model="searchparams"  @keyup.enter.native="sear" placeholder="找大学/查专业/看职业/查资讯"></el-input>
          <div class="divicon" @click="sear()">
            <i class="el-icon-search" style="color: white;"></i>
          </div>
        </div>
        <div class="vipbox" @click="govip">
          开通VIP
          <!-- <img style="width: 100%" src="@/assets/imgs/vipbg.png" alt=""> -->
        </div>
      </div>

    </div>
    <!-- 导航栏 -->
    <div style="width: 100%;background:#12AE96;">
      <div class="navbar">
        <div class="navbaritem indexFz">
          <router-link to="/">首页</router-link>
        </div>
        <div class="navbaritem indexFz">
          <router-link to="examinationdata">高考资料</router-link>
        </div>
        <div class="navbaritem bzy indexFz" ref="bzy" @mouseover="mouzy()" @mouseleave="mouseout()">
          <router-link to="">报志愿 <i style="font-size: 16px;" class="el-icon-arrow-down"></i></router-link>
          <div class="tb" ref="tbref">
            <div @click="changerouter('/fillingZZ')" style="margin:10% 0;">自主填报</div>
            <div @click="changerouter('/fillingZN')">智能填报</div>
          </div>
        </div>
        <div class="navbaritem bzy indexFz" @mouseover="mouzy1()" @mouseleave="mouseout1()">
          <router-link to="">数据查询 <i style="font-size: 16px;" class="el-icon-arrow-down"></i></router-link>
          <div class="tb2" ref="tbref2" >
            <div class="tb3Item">
              <span @click="changerouter('/checkschools')">查大学</span>
              <span @click="changerouter('/advance')">提前批</span>
              <span @click="changerouter('/professionalinvestigation')">查专业</span>
              <span @click="changerouter('/curriculumJudgement')">看评估</span>
            </div>
            <div class="tb3Item" style="border-left: 1px dashed #c0bdbd; ">
              <span @click="changerouter('/occupation')">看职业</span>
              <span @click="changerouter('/Batchline')">批次线</span>
              <span @click="changerouter('/takeatest')">看测评</span>
              <span @click="changerouter('/Onebyone')">查位次</span>
            </div>
          </div>
        </div>
        <div class="navbaritem indexFz">
          <router-link to="/takeatest">测一测</router-link>
        </div>
        <div class="navbaritem indexFz">
          <router-link to="/course">课程</router-link>
        </div>
        <div class="navbaritem indexFz">
          <router-link to="/service">专家服务</router-link>
        </div>
        <div class="navbaritem indexFz">
          <router-link to="/UniversityRankings">排名</router-link>
        </div>

          <ul  v-show="userRouter" @mouseenter="userRouter = true" @mouseleave="userRouter = false">
              <li v-for="(item, index) in arr" :key="item.id" @click="$router.push({ path:'/'+ item.url,query:{id:item.id} })">{{ item.title }}</li>
              <li @click="loginout">退出登录</li>
            </ul>
        <!-- 分割线 -->
        <div class="navbaritem loginandarea">
          <div class="area">
            <i class="el-icon-location-outline" style="font-size: 15px;"></i>
            <span  >河北 |</span>
          </div>
          <div v-if="loginbtnShow" class="loginbtn" @click="loginFlag = true">
            登录
          </div>
          <div class="user" v-else @click="$router.push({ path: '/individual1' })">
            <div style="
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                position:relative
              " @mouseenter="userRouter = true" @mouseleave="userRouter = false">
              <img style="height: 70%;" src="@/assets/imgs/toux.png" alt />
            </div>
            <div style="font-size: 13px;height: 40px;line-height: 40px" @mouseenter="userRouter = true" @mouseleave="userRouter = false" >
              {{ phonenumber }}
            </div>

             <div style="font-size: 13px;margin-left: 20px;">
                  <span class="useruser" v-if="userType == '01'">普通用户</span>
                  <span class="useruser"  v-if="userType == '04'">自主用户</span>
                  <span  class="useruser" v-if="userType == '02' || userType == '03' || userType == '05'">智能用户</span>
            </div>



          </div>
        </div>
      </div>




    </div>
    <div class="logindialog" v-if="loginFlag">
      <div class="loginbox">
        <!-- <div class="left">
          <p>
            1、{{ new Date().getFullYear() + 1 }}届如提前购买，可免费升级到{{ new Date().getFullYear() + 1 }}年10月31日。必须是联网登录后使用,但在{{ new
              Date().getFullYear() }}年报志愿期间，会关闭一些重要数据， 其它时间可正常使用

          </p>
          <p>
            2、{{ new Date().getFullYear() + 2 }}届如提前购买,可免费升级到{{ new Date().getFullYear() + 2 }}年10月31日。必须是联网登录后使用,但在{{ new
              Date().getFullYear() }}届{{ new Date().getFullYear() + 1 }}届报志愿期间， 会关闭重要数据，
            其它时间可正常使用

          </p>
          <p>
            3、购买物理或历史版本的指南针智填软件后，只能使用物理或历史版，如果物理和历史均需使用，请分别购买物理和历史版本。

          </p>
          <p>
            4、本软件不适用艺术生、体育生、美术生。

          </p>
          <p>
            5、家长在购买前请先试用，多多比较，考虑清楚，一经购买，不管有没有激活或使用，不接受退款和转让，复读的第二年须重新全价购买。

          </p>

        </div> -->
        <div class="right">
          <div class="logo">
            <div style="font-size: 32px;line-height: 36px;
    color: #30C9B2;font-weight: bold;">指南针智填</div>
          </div>
          <!-- <h2>登录免费查看全国高校招生录取数据，享受更多高考服务</h2> -->
          <div v-if="loginOrRegister">
            <el-form class="content" ref="refForm" :rules="rules" :model="passwordResetForm">
              <el-form-item prop="phoneNumber">
                <el-input size="medium " clearable v-model="passwordResetForm.phoneNumber" autocomplete="on"
                  placeholder="请输入手机号"></el-input>
              </el-form-item>
              <el-form-item prop="smsCode" class="smsCode" v-if="isShow">
                <el-input size="medium " clearable v-model="passwordResetForm.smsCode"
                  placeholder="请输入短信验证码"></el-input>
                <el-button class="content_elbutton" @click.stop="sendVerificationCode" size="small" type="primary"
                  v-if="show">获取验证码</el-button>
                <el-button class="content_elbutton" size="small" type="primary" v-if="!show" disabled>{{ count
                  }}秒后重发</el-button>
              </el-form-item>
              <!-- <el-form-item prop="password" class="password" v-else>
                <el-input size="medium " clearable v-model="passwordResetForm.password" autocomplete="on" show-password
                  placeholder="请输入密码"></el-input>
              </el-form-item> -->
              <!-- <el-form-item prop="code" class="smsCode" v-if="!isShow">
                <el-input size="medium " maxlength="16" v-model="passwordResetForm.code"
                  placeholder="请输入图形验证码"></el-input>
                <div style="width: 33%;height:38px">
                  <img :src="codeUrl" @click="getCodePic" style="height:38px" />
                </div>
              </el-form-item> -->
              <!-- <div class="sign" style="margin-bottom:10px" v-if="isShow">
                <SliderCheck ref="slider" v-if="!isPhone"></SliderCheck>
              </div> -->
              <div class="sign">
                <el-button @click.stop="sign" type="primary">登 录</el-button>
              </div>
            </el-form>
            <div class="thirdLogin">
              <span>快捷登录方式:</span>
              <img @click="wechatLogin" src="../assets/imgs/wechat.png" alt />
            </div>
            <div class="checkBox">
              <el-checkbox v-show="isShow" v-model="checked"></el-checkbox>
              <h1 v-show="isShow">
                未注册手机登录时会自动创建新账号，请阅读并同意
                <router-link target="_blank" :to="{ path: '/UserAgreement', query: { type: 1 } }">《隐私协议》</router-link>
                <router-link target="_blank" :to="{ path: '/UserAgreement', query: { type: 2 } }">《用户协议》</router-link>
              </h1>
              <!-- <p @click="changeType" v-if="!isShow">短信验证码登录</p>
              <p @click="changeType" v-else>账号密码登录</p> -->
            </div>
          </div>
          <div v-else>
            <el-form class="content" :class="wxchatupdatephoneFlag ? '' : 'contentHeight'" ref="refFormRegi"
              :rules="rulesRegi" :model="regiForm">
              <div v-if="!wxchatupdatephoneFlag">
                <el-form-item label="" prop="studentName">
                  <el-input size="medium" clearable v-model="regiForm.studentName" placeholder="请输入姓名"></el-input>
                </el-form-item>
                <el-form-item label="" prop="graduationSchool">
                  <el-input size="medium" clearable v-model="regiForm.graduationSchool"
                    placeholder="请输入就读高中"></el-input>
                </el-form-item>
                <el-form-item label="" prop="score">
                  <el-input size="medium " clearable v-model.number="regiForm.score"
                    oninput="value=value.replace(/[^0-9.]/g,'')" placeholder="请输入预估分数"></el-input>
                </el-form-item>
                <!-- <el-form-item label="密码" prop="password">
                  <el-input size="medium" clearable v-model="regiForm.password" show-password placeholder="请输入密码"></el-input>
                </el-form-item> -->
                <el-form-item label="" prop="studentClass">
                  <el-select style="width:100%;" v-model="regiForm.studentClass" placeholder="请选择年级">
                    <el-option label="高三" value="高三"></el-option>
                    <el-option label="高二" value="高二"></el-option>
                    <el-option label="高一" value="高一"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="" prop="subject">
                  <el-select style="width:100%;" v-model="regiForm.subject" placeholder="请选择科目">
                    <el-option label="物理" value="物理"></el-option>
                    <el-option label="历史" value="历史"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="" prop="optionalSubject">
                  <el-select style="width:100%;" v-model="regiForm.optionalSubject" multiple placeholder="请选择次选科目">
                    <el-option label="化学" value="化学"></el-option>
                    <el-option label="生物" value="生物"></el-option>
                    <el-option label="地理" value="地理"></el-option>
                    <el-option label="政治" value="政治"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item prop="sex" label="">
                  <el-select style="width:100%;" v-model="regiForm.sex" placeholder="请选择性别">
                    <el-option label="男" value="0"></el-option>
                    <el-option label="女" value="1"></el-option>
                  </el-select>
                </el-form-item>
                <div class="sign">
                  <el-button @click.stop="signCopy" type="primary">开始填报志愿</el-button>
                </div>
              </div>
              <div v-if="wxchatupdatephoneFlag">
                <el-form-item>
                  <el-input size="medium " clearable v-model="passwordResetForm.phoneNumber" autocomplete="on"
                    placeholder="请输入手机号"></el-input>
                </el-form-item>
                <el-form-item class="smsCode">
                  <el-input size="medium " clearable v-model="passwordResetForm.smsCode"
                    placeholder="请输入短信验证码"></el-input>
                  <el-button class="content_elbutton" @click.stop="sendVerificationCode" size="small" type="primary"
                    v-if="show">获取验证码
                  </el-button>
                  <el-button class="content_elbutton" size="small" type="primary" v-if="!show" disabled>{{ count
                    }}秒后重发</el-button>
                </el-form-item>
                <div class="sign">
                  <el-button @click.stop="wechatperfect" type="primary">确 定</el-button>
                </div>
              </div>
            </el-form>
          </div>
        </div>
        <i class="el-icon-circle-close closeIcon" @click.stop="colseDialog" v-if="loginOrRegister"></i>
      </div>
    </div>
    <!-- 支付组件 -->
    <Pay ref="pay"></Pay>
    <el-dialog title="扫码登录" :visible.sync="dialogVisible" width="25%">
      <div style="text-align: center;">
        <div id="weixinLogin"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SliderCheck from '@/components/sliderCheck.vue'
import { REGGETphonecode, REGGETphonecodeCopy, bindWxPhonenumber, REGsmsLogin, REGUserEdit, getUserInfo, getUserInfoCopy, login, getCodeImg, logout, wxlogin } from '@/api/user.js'
import Pay from '@/components/pay.vue'
import { query } from 'quill'

export default {
  name: 'menuBar',
  components: {
    SliderCheck,
    Pay
  },
  props: {
    logoif: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      searchparams: '',
      isPhone: false,
      dialogVisible: false,
      codeUrl: '',
      userRouter: false,
      loginbtnShow: true,
      isShow: true,
      loginOrRegister: true,
      // avatarImg: require("@/assets/imgs/avatarImg.png"),
      location: '河北省',
      passwordResetForm: {
        phoneNumber: '',
        password: '',
        smsCode: '',
        code: ''
      },
      regiForm: {
        score: '',
        sex: '',
        password: '',
        subject: '',
        studentName: '',
        studentClass: '',
        graduationSchool: '',
        optionalSubject: []
      },
      rules: {
        phoneNumber: [
          { required: true, message: '手机号不能为空', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' }
        ],
        smsCode: [
          { required: true, message: '短信验证码不能为空', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '验证码不能为空', trigger: 'blur' }
        ]
      },
      rulesRegi: {
        score: [
          { required: true, message: '分数不能为空', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' }
        ],
        subject: [
          { required: true, message: '科目不能为空', trigger: 'change' }
        ],
        sex: [
          { required: true, message: '性别不能为空', trigger: 'change' }
        ],
        studentClass: [
          { required: true, message: '年级不能为空', trigger: 'change' }
        ],
        studentName: [
          { required: true, message: '姓名不能为空', trigger: 'blur' }
        ],
        optionalSubject: [
          { required: true, message: '次选科目不能为空', trigger: 'change' }
        ]
      },
      show: true,
      count: '',
      timer: null,
      codeUrl: '',
      checked: false,
      loginFlag: false,
      arr: [
        { title: '个人信息', url: 'individual1', id: 1 },
        { title: "我的志愿表", url: "individual2", id: 2 },
        { title: '我的订单', url: 'individual3', id: 3 }
      ],
      uuid: '', // 手机验证码
      uuidPIC: '', // 图文验证码
      isOneLogin: '',
      token: '',
      regiFormCopy: {
        sex: ''
      },
      wxchatupdatephoneFlag: false,
      phonenumberOld: '',
      userType:''
    }
  },
  computed: {
    ...mapState({
      // userType: state => state.userInfo.userType
    })
  },

  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler() {
        if (this.$route.query.code != undefined) {
          const obj = {
            code: this.$route.query.code,
            state: this.$route.query.state
          }
          this.getwechat(obj)
        }
        this.getBreadcrumb()
        console.log(this.$store.state.userInfo.userType)
      }
    }
  },
  created() {
    var info = navigator.userAgent
    // 通过正则表达式的test方法判断是否包含“Mobile”字符串
    var isPhone = /mobile/i.test(info)
    // 如果包含“Mobile”（是手机设备）则返回true
    this.isPhone = isPhone
    this.getBreadcrumb()
    this.getCodePic()
    const phonenumber = localStorage.getItem('phonenumber')
    if(phonenumber){
    this.phonenumber = phonenumber.replace(phonenumber.substring(3, 7), '****')

    }

  },
  mounted() {
    const self = this
    const channel = new BroadcastChannel('share')
    channel.addEventListener('message',(e)=>{
        if(e.data){
          self.loginFlag = true
          self.loginOrRegister = true
        }

    })
  },
  methods: {
    sear() {
      // 路由带参跳转
      if (this.searchparams) {
        this.$router.push({ name: 'sousuo', query: { name: this.searchparams } })
      } else {
        this.$message.warning('请输入搜索内容')
      }

    },
    mouzy() {
      this.$refs.tbref.style.display = 'block'
    },
    mouseout() {
      this.$refs.tbref.style.display = 'none'

    },
    mouzy1() {
      this.$refs.tbref2.style.display = 'flex'
    },
    mouseout1() {
      this.$refs.tbref2.style.display = 'none'
    },
    goFi() {
      const phonenumber = localStorage.getItem('phonenumber')
      if (phonenumber) {
        this.$router.push({
          path: '/fillvolunteer'
        })
      } else {
        this.loginFlag = true
      }
    },
    changerouter(path) {
      this.$router.push({ path, })
    },
    // 跳转vip
    govip(){

        const phonenumber = localStorage.getItem('phonenumber');
        if(!phonenumber){
             this.loginFlag = true
             this.loginOrRegister = true
        }else{
             if(this.userType == '05'){
              return this.$message.success('您已经是智能用户')
             }else{
              this.$router.push('/vip')
             }
        }
    },
    // 微信登录且是第一次登录完善手机号
    wechatperfect() {
      if (!this.passwordResetForm.phoneNumber) {
        this.$message.warning('请输入手机号')
        return
      }
      if (!this.passwordResetForm.smsCode) {
        this.$message.warning('请输入验证码')
        return
      }
      const self = this

      const obj = {
        uuid: this.uuid,
        code: this.passwordResetForm.smsCode,
        bindPhonenumber: this.passwordResetForm.phoneNumber,
        phonenumberOld: this.phonenumberOld
      }
      console.log(obj)
      bindWxPhonenumber(obj).then(res => {
        res.data.optionalSubject = res.data.optionalSubject ? JSON.parse(res.data.optionalSubject) : []
        res.data.score = res.data.score == 0 ? '' : res.data.score
        self.regiForm = res.data
        self.wxchatupdatephoneFlag = false
        self.successLogin()
      })
    },
    // 把code和state传给后端
    getwechat(obj) {
      const self = this
      if(localStorage.getItem('phonenumber')){
          return
      }
      wxlogin(obj).then(res => {
        self.token = res.data.token
        localStorage.setItem('token', self.token)
        if (res.data.isOneLogin == '0') { // 第一次登录完善个人信息
          self.$message.success('请完善个人信息')
          self.phonenumberOld = res.data.name
          self.loginbtnShow = true // 登录注册按钮显示
          self.loginOrRegister = false // 完善个人信息盒子显示
          self.wxchatupdatephoneFlag = true // 第一次登录且是微信登录，让用户补充手机号。
          self.dialogVisible = false // 二维码弹框关闭
        } else { // 不是第一次登录，进行逻辑判断


          self.passwordResetForm.phoneNumber = res.data.name
          self.successLogin()
        }
      }).catch(() => {
        self.wechatLogin()
      })
    },
    // 微信登录【出二维码】
    wechatLogin() {
      const self = this
      self.dialogVisible = true
      let redirect_uri = ''
      redirect_uri = process.env.VUE_APP_BASE_API.includes('https') ? encodeURIComponent('https://www.zyznz-edu.cn/#/') : encodeURIComponent('http://www.zyznz-edu.cn/#/')
      self.$nextTick(() => {
        var obj = new WxLogin({
          self_redirect: false,
          id: 'weixinLogin',
          appid: 'wx54661bbe02fc3377',
          scope: 'snsapi_login',
          redirect_uri: redirect_uri,
          state: Math.ceil(Math.random() * 1000),
          style: 'black',
          href: ''
        })
      })
    },
    // 账号登录或者验证码登录 切换
    changeType() {
      this.isShow = !this.isShow
      this.$refs.slider.reset() // 重置滑动
      this.$refs.refForm.resetFields() // 清除表单验证
      clearInterval(this.timer) // 重置获取验证码计时器
      this.timer = null // 重置获取验证码计时器
      this.show = true // 重置获取验证码计时器
      this.passwordResetForm = {
        phoneNumber: '',
        password: '',
        smsCode: '',
        code: ''

      }
    },

    getBreadcrumb() {
      const phonenumber = localStorage.getItem('phonenumber')
      if (phonenumber) {
          this.loginbtnShow = false
          this.phonenumber = phonenumber.replace(phonenumber.substring(3, 7), '****')
          this.userType = this.$store.state.userInfo.userType
      } else {
        this.loginbtnShow = true
      }
      // 调接口，拿用户信息。【已经是promise，可进行同步】
      // this.$store.dispatch('setUserinfo')
    },
    // 获取图文验证码
    getCodePic() {
      const self = this;
      const phonenumber = localStorage.getItem('phonenumber')
      if(!phonenumber){
            getCodeImg().then(res => {
        self.codeUrl = 'data:image/gif;base64,' + res.img
        self.uuidPIC = res.uuid
      })
      }

    },
    // 手机验证码 倒计时
    sendVerificationCode() {
      // if (!this.$refs.slider.confirmSuccess) return this.$message('请拖动滑块验证')
      // /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      //  /^1((3[\d])|(4[5,6,9])|(5[0-3,5-9])|(6[5-7])|(7[0-8])|(8[1-3,5-8])|(9[1,8,9]))\d{8}$/
      const reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
      if (!reg.test(this.passwordResetForm.phoneNumber)) {
        this.$message.warning('请输入正确格式手机号')
        return
      }
        if (!this.checked) {
            this.$message.warning('请先勾选用户协议')
            return
          }
      const TIME_COUNT = 60
      if (!this.timer) {
        this.count = TIME_COUNT
        this.show = false
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--
          } else {
            this.show = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
        this.getCode()
      }
    },
    // 获取手机验证码
    getCode() {
      const self = this
      if (this.wxchatupdatephoneFlag) { // 微信注册信息
        REGGETphonecodeCopy({ phoneNumber: this.passwordResetForm.phoneNumber }).then((res) => {
          self.uuid = res.uuid
          // self.passwordResetForm.smsCode = res.smsCode
        })
      } else { // 正常手机号获取验证码
        REGGETphonecode({ phoneNumber: this.passwordResetForm.phoneNumber }).then((res) => {
          self.uuid = res.uuid
          // self.passwordResetForm.smsCode = res.smsCode
        })
      }
    },
    // 登录
    sign() {
      const self = this
      const reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
      if (!reg.test(this.passwordResetForm.phoneNumber)) {
        this.$message.warning('请输入正确格式手机号')
        return
      }
      this.$refs.refForm.validate((valid) => {
        if (valid) {
          if (self.$refs.slider) {
            // 滑块未通过验证时，提示错误信息并返回
            if (!self.$refs.slider.confirmSuccess) {
              return self.$message.warning('请拖动滑块验证')
            }
          }
          if (!self.checked) {
            self.$message.warning('请先勾选用户协议')
          } else {
            if (self.isShow) {
              // console.log('短信验证码登录')
              const obj = {
                phoneNumber: self.passwordResetForm.phoneNumber,
                smsCode: self.passwordResetForm.smsCode,
                uuid: self.uuid,
                invitationCode: 'testCode',
                loginType: '1',
                loginCode: ''
              }
              REGsmsLogin(obj).then(res => {
                self.token = res.token
                self.successLogin()
                return
                if (res.isOneLogin == '0') { // 第一次登录，需要完善个人信息
                  self.loginbtnShow = true
                  self.loginOrRegister = false // 此处需要判断，如果是第一次登录，则将此变量为false；反之则为true
                  self.token = res.token
                } else { // 不是第一次登录，进行逻辑判断
                  self.token = res.token
                  self.successLogin()
                }
              })
            } else {
              // console.log('密码登录')
              const obj = {
                username: this.passwordResetForm.phoneNumber,
                password: this.passwordResetForm.password,
                code: this.passwordResetForm.code,
                uuid: this.uuidPIC,
                loginChannel: 'QT'
              }
              // if (obj.password.length < 8) {
              //   this.$message.error('密码长度为8-12位 ')
              //   return
              // }
              // if (obj.password.length > 12) {
              //   this.$message.error('密码长度为8-12位 ')
              //   return
              // }
              login(obj).then(res => {
                if (res.code == 200) {
                  self.token = res.token
                  self.successLogin()
                }
              }).catch(err => {
                self.getCodePic()
              })
            }
          }
        } else {

          return false
        }
      })
    },
    // 信息完善
    signCopy() {
      const self = this
      const obj = {
        phonenumber: this.passwordResetForm.phoneNumber,
        password: this.regiForm.password,
        score: this.regiForm.score,
        subject: this.regiForm.subject,
        userId: this.passwordResetForm.phoneNumber,
        sex: this.regiForm.sex,
        studentName: this.regiForm.studentName,
        studentClass: this.regiForm.studentClass,
        graduationSchool: this.regiForm.graduationSchool,
        optionalSubject: JSON.stringify(this.regiForm.optionalSubject)
      }
      if (!obj.score) {
        this.$message.error('请输入预估分数')
        return
      }
      if (isNaN(Number(obj.score))) {
        this.$message.error('请输入准确的预估分数')
        return
      }
      if (!(Number(obj.score) >= 1 && Number(obj.score) <= 750)) {
        this.$message.error('请输入准确的预估分数')
        return
      }
      this.$refs.refFormRegi.validate((valid) => {
        if (valid) {
          if (this.regiForm.optionalSubject.length !== 2 || !this.regiForm.optionalSubject) return this.$message.warning('次选科目必选且只能选择两项')
          // if (obj.password.length < 8) {
          //   this.$message.error('密码长度为8-12位')
          //   return
          // }
          // if (obj.password.length > 12) {
          //   this.$message.error('密码长度为8-12位')
          //   return
          // }
          REGUserEdit(obj).then(res => {
            if (res.code == 200) {
              self.successLogin()
              this.regiForm = {
                score: '',
                sex: '',
                password: '',
                subject: '',
                studentName: '',
                studentClass: '',
                graduationSchool: '',
                optionalSubject: ''
              }
            }
          })
        }
      })
    },
    async successLogin() {

      localStorage.setItem('token', this.token)
      localStorage.setItem('phonenumber', this.passwordResetForm.phoneNumber)
      this.loginbtnShow = false // 登录成功，此变量变成false
      this.loginOrRegister = true
      this.wxchatupdatephoneFlag = false // 登陆成功，让微信登录且是第一次登录的盒子（只包含手机号和验证码）隐藏
      this.colseDialog()   //弹框关闭
      await this.$store.dispatch('setUserinfo')  //调取接口，获取用户资料。
      this.userType = this.$store.state.userInfo.userType;
      this.$message({ message: '登录成功', type: 'success', duration: 1000 })
      if(this.$route.path == '/index' || this.$route.path == '/profile') {  //如果是首页或者学校详情页面，刷新页面。
            this.$router.go(0)
      }
      this.getBreadcrumb()
    },
    // 点击icon关闭登录弹框 登录成功关闭登录弹框
    colseDialog() {
      const self = this
      this.loginFlag = false
      this.dialogVisible = false
      this.passwordResetForm = {
        phoneNumber: '',
        password: '',
        smsCode: '',
        code: '',
        uuid: ''
      }
      self.$nextTick(() => {
        if (self.$refs.refFormRegi) {
          self.$refs.refFormRegi.resetFields() // 清除表单验证
        }
      })

      this.show = true
      this.isShow = true
      clearInterval(this.timer)
      this.timer = null
    },
    // 退出登录
    loginout() {
      const self = this
      this.$confirm('确定退出吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          logout().then(res => {
            if (res.code == 200) {
              self.$message.success('退出成功')
              localStorage.removeItem('token')
              localStorage.removeItem('phonenumber')
              self.loginbtnShow = true
              self.$store.dispatch('resetUserInfo')
              if (self.$route.fullPath == '/') { // 如果当前页面是首页，那么退出登录刷新页面。
                self.$router.go(0)
              } else {
                self.$router.push({ path: '/' })
                self.$router.go(0)
              }
            }
          })
        })
        .catch(() => {
          this.$message.warning('取消退出')
        })
    },
    // 开通vip
    vipSubmit() {
      if (!localStorage.getItem('phonenumber')) return this.loginFlag = true
      this.$refs.pay.isShow = true
    }
  }
}
</script>

<style scoped lang="scss">
.menuBar {
  user-select: none;
  font-size: 14px;
  color: #333;
  width: 100%;
  background-color: white;
  position: relative;
  // height: 90px;

  .logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 10px 0;
    box-sizing: border-box;
    justify-content: center;
    width: 100%;

    // margin: 48px 0 45px 0;
    ::v-deep .searchInput {
      margin-left: auto;
      position: relative;
      width: 535px;
      height: 45px;

      .el-input .el-input__inner {
        border-radius: 20px;
        border: 1px solid #12B098;
      }

      .divicon {
        background: #12B098;
        position: absolute;
        height: 40px;
        width: 50px;
        text-align: center;
        line-height: 40px;
        right: 0px;
        top: 0px;
        border-radius: 0 20px 20px 0;
      }
    }
    .vipbox{
     width: 110px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #fff;
    font-size: 20px;
    background: #ffecb6;

    margin-top: -5px;
    cursor: pointer;
    color: #c09f42;
    margin-left: 20px;
    }
  }

  .barbox {
    width: 1200px;
    font-size: 18px;
    color: white;
    margin: 0 auto;
    padding: 2px 0;
    align-items: center;
    justify-content: space-between;



    .other {
      height: 100%;
      // width: 360px;
      display: flex;
      align-items: center;
      justify-content: right;
    }

    // .loginbtn {
    //   width: 120px;
    //   height: 40px;
    //   font-size: 16px;
    //   color: white;
    //   background-color: #30C9B2;
    //   border-radius: 5px;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   cursor: pointer;
    // }
  }

  .navbar {
    width: 1200px;
    position: relative;
    margin: 0 auto;
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: space-between;

    .navbaritem {
      // width: 120px;
      text-align: center;
      font-size: 20px;
      font-family: PingFang SC;

      color: #FFFFFF;
      // flex: 1;
    }

    .navbaritem a {
      color: white;
       font-weight: 900;
    }

    .loginandarea {
      width: 260px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .loginbtn {
        width: 63px;
        background: white;
        border-radius: 15px;
        height: 28px;
        text-align: center;
        line-height: 28px;
        color: #029570;
        font-size: 14px;
      }
    }
    .area{
     font-size: 13px;
    // margin-left: 85px;
    }
        ul {
      position: absolute;
      z-index: 999;
     right: 4%;
    top: 100%;
      color: black;
      width: 145px;
      text-align: center;
      border-radius: 10px;
      box-shadow: 0 0 20px #ccc;
    }

    li {
      background: white;
      list-style: none;
      height: 35px;
      line-height: 15px;
      font-size: 13px;
      padding: 10px;
      box-sizing: border-box;
    }

    li:hover {
      background: #e7fdf1;
    }

    li:last-of-type {
      color: #30C9B2;
      border-top: 1px solid #ccc;
    }



  }

  .user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;

    img {
      height: 50%;
      margin-left: 10px;
    }
    .useruser{
     background: #0E5744;
    border-radius: 15px;
      padding: 2px 5px;
    color: #BEF6E6;
    display: inline-block;
    }

  }

  .logindialog {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);

    .loginbox {
      // height: 500px;
      position: absolute;
      width: 675px;
      // border: 1px solid black;
      display: flex;
      // border-radius: 50%;

      .left {
        flex: 4.5;
        background: #30C9B2;
        box-sizing: border-box;
        padding: 40px 15px;
        font-size: 16px;

        p {
          color: white;
          line-height: 30px;
          margin: 10px 0;
        }
      }

      .right {
        flex: 5.5;
        text-align: center;
        background: white;
        box-sizing: border-box;
        padding: 20px 0px 35px 0;
        border-radius: 15px;

        h2 {
          margin: 37px 0 30px 0px;
          font-size: 16px;
        }
      }
    }
  }
}

.closeIcon {
  position: absolute;
  right: 0px;
  top: -42px;
  color: white;
  font-size: 40px;
  z-index: 22;
  cursor: pointer;
}


.checkBox {
  text-align: left;
  padding-left: 40px;
  font-size: 17px;

  a {
    cursor: pointer;
    color: #30C9B2;
  }

  p {
    float: right;
    margin-right: 50px;
    cursor: pointer;
    color: #446dff;
  }

  h1 {
    // width: 60%;
    margin-left: 10px;
    vertical-align: middle;
    display: inline-block;
  }
}

::v-deep .content {
  // width: 490px;
  padding: 0px 32px;
  margin-top: 15px;

  .el-form-item__label {
    position: relative;
  }

  .el-form-item__label::before {
    position: absolute;
    left: 71px;
    top: -3px;
  }

  .el-input__inner {
    height: 50px;
    font-size: 25px;
    background: #ECECEC;
  }

  .content_elbutton {
    height: 50px;
    line-height: 32px;
    font-size: 20px;
    width: 234px;
    background: #FEFEFE;
    border: 1px solid #30C9B2;
    border-radius: 4px;
    width: 234px;
    color: #30C9B2;
    margin-left: 10px;
  }
}

.contentHeight {
  height: 360px;
  overflow-y: scroll;
}

.contentHeight::-webkit-scrollbar {
  width: 8px;
}

.contentHeight::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  opacity: 0.2;
  background-color: #ddd;
  border-radius: 10px;
}

.contentHeight::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
}

.smsCode ::v-deep .el-form-item__content {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

::v-deep .el-form-item__label {
  font-size: 18px;
  font-weight: bold;
  padding: 0 0 0 0;
  color: #fff;
  text-align: left;
}

.sign {
  // padding-left: 89px;
  box-sizing: border-box;
  border-radius: 10px;
}

.sign button {
  font-size: 25px;
  background: #30C9B2;
  width: 100%;
  border: none;
  height: 55px;
}

.login-code {
  width: 33%;
  height: 38px;
  float: right;

  img {
    cursor: pointer;
    vertical-align: middle;
  }
}

.thirdLogin {
  width: 100%;
  margin: 30px 0;
  text-align: left;
  padding-left: 35px;

  span {
    font-size: 20px;
    color: #595959;
    margin-right: 10px;
  }

  img {
    height: 25px;
    vertical-align: middle;
    margin-top: -7px;
  }
}

.tb {
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 75%;
  left: -34%;
  padding: 5px 20px;
  text-align: center;
  z-index: 9;
  display: none;
  font-size: 15px;

  div {
    color: #fff;
    margin-top: 13%;
    margin-bottom: 10%;
    font-weight: 500;

  }

  div:hover {
    color: #c0bdbd;
    cursor: pointer;
  }

}

.tb2 {
  width: 160%;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
   top: 75%;

  left: -35%;
  text-align: center;
  z-index: 9;
  font-size: 15px;
  display: none;

  // padding: 0 5px;
  justify-content: space-around;

  .tb3Item {
    width: 50%;
    // display: flex;
    flex-wrap: wrap;
    // margin: 5% 0;
    padding-top:7%;
    text-align: center;
    span {
      display: block;
      margin-bottom:15%;
      cursor: pointer;
    }
    span:hover {
      color: #c0bdbd;

    }

  }

  .tb3Item2 {
    width: 48%;
    border-left: 1px dashed #c0bdbd;
    height: 90%;

    div {
      margin: 17%;

    }

    div:hover {
      color: #c0bdbd;
      cursor: pointer;
    }
  }
}


.bzy {
  position: relative;
  height: 100%;
  margin-top: 17px;
  // margin-top: 1%;

}
</style>
